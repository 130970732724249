.divider-section {
    margin: 40px 0;
}

.ma-l {
    margin: 40px 0;
}

.mb-l {
    margin: 0 0 40px;
}

.navbar {
    margin: 0 0 24px;
}